import { Box, Card, Stack, useTheme } from "@mui/material";
import { t } from "i18next";
import { InfoMore } from "src/assets";
import FormTitle from "src/components/Displays/FormTitle";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import PageTitle from "src/components/Displays/PageTitle";
import ElectricityCost from "./ElectricityCost";
import EnergyManagement from "./EnergyManagement";
import IntelligentMeasuer from "./IntelligentMeasure";
import ModalContent from "./ModalContent";
import Solar from "./Solar";
import Modal from "src/components/Modal/Modal";
import { Tariff } from "src/models/clientsModel";

interface TarifSectionProps {
  children?: React.ReactNode;
  expanded: string | boolean;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  isOverlayOpen: boolean;
  toggleIsOverlayOpen: () => void;
  tariffsData?: Tariff[];
}

const TarifSection = ({
  expanded,
  handleChange,
  isOverlayOpen,
  toggleIsOverlayOpen,
  children,
  tariffsData,
}: TarifSectionProps) => {
  const theme = useTheme();
  return (
    <Stack>
      <Modal
        title={
          <PageTitle
            color={theme.palette.grey[500]}
            text={t("page.tariff.modal.title")}
          />
        }
        isOpen={isOverlayOpen}
        onClose={toggleIsOverlayOpen}
        onSubmit={toggleIsOverlayOpen}
      >
        <ModalContent />
      </Modal>
      <Card
        elevation={0}
        sx={(theme) => ({
          borderRadius: 2,
          background: theme.palette.form.background,
          border: "1px solid lightgray",

          p: 3,
        })}
      >
        <Box>
          <FormTitle text={t("page.tariff.title")} />
        </Box>

        <Stack>
          <Solar
            expanded={expanded}
            handleChange={handleChange}
            tariffsData={tariffsData}
          />
          <ElectricityCost
            expanded={expanded}
            handleChange={handleChange}
            toggleIsOverlayOpen={toggleIsOverlayOpen}
            tariffsData={tariffsData}
          />
          <EnergyManagement
            expanded={expanded}
            handleChange={handleChange}
            tariffsData={tariffsData}
          />
          <IntelligentMeasuer
            expanded={expanded}
            handleChange={handleChange}
            tariffsData={tariffsData}
          />
        </Stack>

        <Stack
          direction="row"
          gap={2}
          sx={(theme) => ({
            borderRadius: 2,
            backgroundColor: theme.palette.grey[50],
            border: `1px solid ${theme.palette.grey[600]}`,
            p: 3,
            mt: 3,
          })}
        >
          <Box>
            <InfoMore />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.info")}
          />
        </Stack>

        {children}
      </Card>
    </Stack>
  );
};

export default TarifSection;
