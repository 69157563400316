import { Typography, styled } from "@mui/material";
import { ReactNode } from "react";

interface ProgressBarTitleProps {
  text: string | ReactNode;
  color?: string;
}

const ProgressBarTitle = ({ text, color }: ProgressBarTitleProps) => {
  const ProgressBarTitleStyles = styled(Typography)(({ theme }) => ({
    color: color || theme.palette.app.primaryFont,
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    whiteSpace: "pre-wrap",
  }));

  return <ProgressBarTitleStyles>{text}</ProgressBarTitleStyles>;
};

export default ProgressBarTitle;
