import { Box, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CheckCircle } from "src/assets";
import ControlledAccordion from "src/components/Accordion/ControlledAccordion";
import PageSubTitle from "src/components/Displays/PageSubTitle";
import Text from "src/components/Displays/Text";
import { Tariff } from "src/models/clientsModel";

interface SolarProps {
  expanded: string | boolean;
  handleChange: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  tariffsData?: Tariff[];
}

const Solar = ({ expanded, handleChange, tariffsData }: SolarProps) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const savingsData = tariffsData && tariffsData[0];
  const revenueFeedInSign =
    (savingsData && savingsData.savings?.revenueFeedInSign) ?? "";
  const revenueFeedInMonthly =
    (savingsData && savingsData.savings?.revenueFeedInMonthly) ?? "";
  const revenueFeedInYearly =
    (savingsData && savingsData.savings?.revenueFeedInYearly) ?? "";

  return (
    <ControlledAccordion
      title={t("page.tariff.solar.title")}
      subtitleCollapsed={t("page.tariff.solar.subtitle")}
      subtitleUnCollapsed={t("page.tariff.solar.dynamic")}
      backgroundColor={theme.palette.form.background}
      value={t("page.tariff.solar.monthFormat", {
        revenueFeedInSign,
        revenueFeedInMonthly,
      })}
      expanded={expanded}
      handleChange={handleChange}
      name="solar"
    >
      <Stack direction="row" justifyContent="space-between">
        <Text
          fontWeight={700}
          sx={{
            fontSize: "11px",
            lineHeight: "18px",
          }}
          text={t("page.tariff.solar.year")}
        />
        <Text
          fontWeight={700}
          sx={{
            fontSize: "11px",
            lineHeight: "18px",
          }}
          text={t("page.tariff.solar.yearFormat", {
            revenueFeedInSign,
            revenueFeedInYearly,
          })}
        />
      </Stack>

      <Stack
        gap={3}
        sx={{
          mt: 6,
        }}
      >
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.solar.excessElectricity")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.solar.priceCompensation")}
          />
        </Stack>
        <Box sx={{ ml: 8 }}>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.solar.description1")}
          />
        </Box>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.solar.remuneration")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.solar.basicPrice")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.solar.contractTerm")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.solar.noticePeriod")}
          />
        </Stack>
        <Stack direction="row" gap={2}>
          <Box>
            <CheckCircle />
          </Box>
          <PageSubTitle
            color={theme.palette.app.secondaryFont}
            text={t("page.tariff.solar.billingMonthly")}
          />
        </Stack>
      </Stack>
    </ControlledAccordion>
  );
};

export default Solar;
